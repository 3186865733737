namespace Ozytis {
	export class Utilities {
        public static newGuid() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        public static toRomanNumerals(num: number): string {
            switch (num) {
                case 1: return 'I';
                case 2: return 'II';
                case 3: return 'III';
                case 4: return 'IV';
                case 5: return 'V';
                case 6: return 'VI';
                case 7: return 'VII';
                case 8: return 'VIII';
                case 9: return 'IX';
                case 10: return 'X';
                case 11: return 'XI';
                case 12: return 'XII';
                case 13: return 'XIII';
                case 14: return 'XIV';
                case 15: return 'XV';
                case 16: return 'XVI';
                case 17: return 'XVII';
                case 18: return 'XVIII';
                case 19: return 'XIX';
                case 20: return 'XX';
                default: throw new Error("Roman numeral generation not implemented above 20");
            }
        }

        public static toLetterNumerals(num: number): string {
            if (num < 1 || num > 26)
                throw new Error("Number out of range: " + num);
            return String.fromCharCode('A'.charCodeAt(0) + num - 1)
        }

        public static escapeRegex(str: string): string {
            return (str + '').replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&");
        }

        public static highlightHtml(str: string, words: string[], highlightClass: string, allowPartialWords = false): string {

            // the regexp class \b (match beginning/end of word) would be better, but for some reason it doesn't work if the word last character has an accent (at least in Firefox)
            let commonSeparators = '  .,;!?()\r\n\'"’-_@&';
            let wordRightSeparator = '([\\s' + Ozytis.Utilities.escapeRegex(commonSeparators + '<') + '])';
            let wordLeftSeparator = '([\\s' + Ozytis.Utilities.escapeRegex(commonSeparators + '>') + '])';

            let original = str;

            for (let word of words) {
                let regex = Ozytis.Utilities.escapeRegex(word);
                regex = regex.replace(/[aà]/gi, '[aà]');
                regex = regex.replace(/[eéèëê]/gi, '[eéèëê]');
                regex = regex.replace(/[iï]/gi, '[iï]');
                regex = regex.replace(/[oô]/gi, '[oô]');
                regex = regex.replace(/[uù]/gi, '[uù]');
                regex = regex.replace(/[cç]/gi, '[cç]');
                str = str.replace(new RegExp(wordLeftSeparator + '(' + regex + ')' + wordRightSeparator, 'gi'), '\$1<span class="' + highlightClass + '">\$2</span>\$3');
                str = str.replace(new RegExp('^(' + regex + ')' + wordRightSeparator, 'gi'), '<span class="' + highlightClass + '">\$1</span>\$2');
                str = str.replace(new RegExp(wordLeftSeparator + '(' + regex + ')$', 'gi'), '\$1<span class="' + highlightClass + '">\$2</span>');
                str = str.replace(new RegExp('^(' + regex + ')$', 'gi'), '<span class="' + highlightClass + '">\$1</span>');
            }

            if (str == original && allowPartialWords) {
                for (let word of words) {
                    let regex = Ozytis.Utilities.escapeRegex(word);
                    regex = regex.replace(/[aà]/gi, '[aà]');
                    regex = regex.replace(/[eéèëê]/gi, '[eéèëê]');
                    regex = regex.replace(/[iï]/gi, '[iï]');
                    regex = regex.replace(/[oô]/gi, '[oô]');
                    regex = regex.replace(/[uù]/gi, '[uù]');
                    regex = regex.replace(/[cç]/gi, '[cç]');
                    str = str.replace(new RegExp('(' + regex + ')', 'gi'), '<span class="' + highlightClass + '">\$1</span>');
                }
            }

            return str;
        }

        public static unhighlightHtml(html: string, highlightClass: string): string {
            var container = $('<div>' + html + '</div>');
            Utilities.unhighlight(container, highlightClass);
            return container.html();
        }

        public static unhighlight(element: JQuery, highlightClass: string): void {
            element.find('span.' + highlightClass).each(function () {
                var span = $(this);
                span.removeClass(highlightClass);
                if ((!span.attr('class') || span.attr('class').trim() == '') && (!span.attr('id') || span.attr('id').trim() == '')) {
                    span.after(span.text());
                    span.remove();
                }
            });
        }

        public static removeDiacritics(str: string): string {
            str = str.replace(/[aà]/gi, 'a');
            str = str.replace(/[eéèëê]/gi, 'e');
            str = str.replace(/[iï]/gi, 'i');
            str = str.replace(/[oô]/gi, 'o');
            str = str.replace(/[uù]/gi, 'u');
            str = str.replace(/[cç]/gi, 'c');
            return str;
        }

        public static sanitizeFileName(fileName: string): string {

            return Ozytis.Utilities.removeDiacritics(fileName).replace(/[^a-zA-Z0-9\.\-]/gi, '_');
		}

        public static getCookie(name): string {
            var parts = document.cookie.split(name + "=");
            if (parts.length == 2) return parts.pop().split(";").shift();
            return null;
        }

        public static htmlEncode(plainText: string): string {
            return plainText
                .replaceAll('&', '&amp;')
                .replaceAll('"', '&quot;')
                .replaceAll('\'', '&#39;')
                .replaceAll('<', '&lt;')
                .replaceAll('>', '&gt;')
                .replaceAll('\/', '&#x2F;');
        }

        public static htmlDecode(htmlEncodedText: string): string {
            return htmlEncodedText
                .replaceAll('&quot;', '"')
                .replaceAll('&#39;', "'")
                .replaceAll('&lt;', '<')
                .replaceAll('&gt;', '>')
                .replaceAll('&amp;', '&')
                .replaceAll('&#x2F;', '/');
        }

        /*
         * Computes the binary AND between the operands, as if they were 64bit integers.
         * Since javascript numbers are floating point with double precision, integer precision is actually limited to 52bits, after which lowest bits are truncated.
         */
        public static binaryAnd64bits(a: number, b: number) {
            if (a < 0 || b < 0)
                throw new Error('This method has not been tested on negative numbers');
            if (a > 4503599627370496 || b > 4503599627370496)
                throw new Error('Numbers are too big');
            let highA = Math.floor(a / 4294967296 + 0.5);
            let highB = Math.floor(b / 4294967296 + 0.5);
            return (highA & highB) * 4294967296 + (a & b);
        }
    }
}
